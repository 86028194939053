import { combineEpics, Epic } from 'redux-observable'
import subscriptionEpics from './fetchSubscriptionsEpic'
import athleteProfileEpics from './fetchAthleteProfileEpic'
import userMetadataEpics from './fetchUserMetadataEpic'
import userPaymentEpics from './fetchUserPaymentEpic'
import workoutsEpics from './fetchWorkoutsEpic'
import likedWorkoutsEpics from './fetchLikedWorkoutsEpic'
import completedWorkoutsEpics from './fetchCompletedWorkoutsEpic'
import scheduledWorkoutsEpics from './fetchScheduledWorkoutsEpic'
import workoutByIdEpics from './fetchWorkoutByIdEpic'

const epicRegistry: Epic<any, any, any, any>[] = [
  subscriptionEpics,
  athleteProfileEpics,
  userMetadataEpics,
  userPaymentEpics,
  workoutsEpics,
  likedWorkoutsEpics,
  completedWorkoutsEpics,
  scheduledWorkoutsEpics,
  workoutByIdEpics,
]

export default combineEpics(...epicRegistry)
