import { ActionType, createAsyncAction } from 'typesafe-actions'

export interface AthleteProfileResponse {
  userId: number
  username: string
  firstName: string
  lastName: string
  birthday: string
  gender: string
  location: string
  phoneNumber: string
  profilePictureUrl: string
  email: string
}

export const onFetchAthleteProfile = createAsyncAction(
  'profile/FETCH_ATHLETE_PROFILE',
  'profile/FETCH_ATHLETE_PROFILE_SUCCESS',
  'profile/FETCH_ATHLETE_PROFILE_FAILURE',
)<void, AthleteProfileResponse, Error>()

export type AthleteProfileActions = ActionType<typeof onFetchAthleteProfile>
