export const isOnBrowser = (): boolean => typeof window !== 'undefined'

export const scrollToTop = (): void => {
  if (typeof window === 'undefined') return
  window.scrollTo(0, 0)
}

/**
 * If set is `undefined` (not provided) or `true`, some browsers will show user
 * an alert asking them to make sure they want to navigate away from the page.
 *
 * If set is `false`, this alert will be disabled on all browsers. Note that
 * this will not carry over between pages
 *
 * @param set
 */
export const setWarnUserBeforeLeaving = (set?: boolean): void => {
  if (typeof window === 'undefined') return
  if (set === undefined || set === true) {
    window.onbeforeunload = (): true => true
    return
  }
  window.onbeforeunload = null
}
