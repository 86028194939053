import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchCompletedWorkouts,
  CompletedWorkoutActions,
  CompletedWorkoutResponse,
} from '../actions'

const fetchCompletedWorkouts: Epic = (
  action$: ActionsObservable<CompletedWorkoutActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchCompletedWorkouts.request)),
    mergeMap(() =>
      api$(Methods.get, '/mark_completed').pipe(
        map((completedWorkouts: any) =>
          onFetchCompletedWorkouts.success(
            completedWorkouts as CompletedWorkoutResponse[],
          ),
        ),
        catchError(err => of(onFetchCompletedWorkouts.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchCompletedWorkouts)
