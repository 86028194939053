import {
  API_CREATE_WORKOUT_ROUTE,
  API_UPLOAD_WORKOUT_VIDEO_ROUTE,
  API_GET_WORKOUT_ROUTE,
  API_DELETE_WORKOUT_ROUTE,
  API_UPDATE_WORKOUT_ROUTE,
  API_UPDATE_WORKOUT_VIDEO_ROUTE,
  API_DELETE_WORKOUT_VIDEO_ROUTE,
  API_PURCHASE_LIVE_WORKOUT,
} from '../apiRoutes'
import request from '../request'
import {
  EContentType,
  EResponseType,
  TId,
  EWorkoutType,
  EWorkoutIntensity,
  EMuscleGroups,
  EEquipment,
  EWorkoutFormat,
  IWorkout,
} from '../../types'
import * as logger from '../logger'
import { noop } from '../helpers'

export interface IWorkoutBody {
  title: string
  description: string
  type: EWorkoutType
  intensity: EWorkoutIntensity
  muscles: EMuscleGroups[]
  equipment: EEquipment
  format: EWorkoutFormat
  length: number
}

interface ICreateWorkoutResponse {
  status: string
  workoutId: TId
}

export const fetchCreateWorkout = async (
  body: IWorkoutBody,
): Promise<ICreateWorkoutResponse> =>
  request(API_CREATE_WORKOUT_ROUTE, body).then(
    res => res as ICreateWorkoutResponse,
  )

export const purchaseLiveClass = async (
  id: number,
  priceId: string,
  taxId?: string,
): Promise<any> =>
  request(API_PURCHASE_LIVE_WORKOUT(id), { priceId, taxId }).then(res => res)

export const fetchWorkout = async (id: TId): Promise<IWorkout> =>
  request(API_GET_WORKOUT_ROUTE(id)).then(res => res as IWorkout)

export const fetchUploadWorkoutVideo = async (
  id: TId,
  data: FormData,
): Promise<void> =>
  request(API_UPLOAD_WORKOUT_VIDEO_ROUTE(id), data, {
    responseType: EResponseType.JSON,
    contentType: EContentType.FORM_DATA,
  }).then(res => {
    logger.log(res)
    return
  })

interface IUpdateWorkoutVideoBody {
  title?: string
  description?: string
  sequence?: number
}

export const fetchUpdateWorkoutVideo = async (
  workoutId: TId,
  videoId: TId,
  body: IUpdateWorkoutVideoBody,
): Promise<void> =>
  request(API_UPDATE_WORKOUT_VIDEO_ROUTE(workoutId, videoId), body).then(noop)

/**
 * Update workout. NOTE this does not update workout vidoes.
 *
 * @param id of the workout
 * @param body new workout information
 */
export const fetchUpdateWorkout = async (
  id: TId,
  body: IWorkoutBody,
): Promise<void> => request(API_UPDATE_WORKOUT_ROUTE(id), body).then(noop)

/**
 * Permanently delete a workout from the database
 *
 * @param id of the workout
 */
export const fetchDeleteWorkout = async (id: TId): Promise<void> =>
  request(API_DELETE_WORKOUT_ROUTE(id)).then(noop)

export const fetchDeleteWorkoutStep = async (
  workoutId: TId,
  videoId: TId,
): Promise<void> =>
  request(API_DELETE_WORKOUT_VIDEO_ROUTE(workoutId, videoId)).then(noop)
