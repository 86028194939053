import request from '../../services/request'
import {
  API_DELETE_ATHLETE_ROUTE,
  API_GET_ATHLETE_ROUTE,
  API_UPDATE_ATHLETE_ROUTE,
  API_GET_USER_STATS,
} from '../apiRoutes'
import { TId, EState, EGender } from '../../types'
import { noop } from '../helpers'
import { SubscriptionStatus } from '../../services/session'
import { SubscriptionType } from '../../services/fetch/payments'

/**
 * Get athlete
 */

export interface IAthleteResponse {
  birthday?: string
  city?: string
  createdAt: string
  email: string
  firstName: string
  gender?: EGender
  userId: TId
  lastName: string
  phoneNumber?: string
  state?: EState
  updatedAt: string
  zipcode?: number
  profilePictureUrl: string | null
}

export const fetchAthlete = async (): Promise<IAthleteResponse> =>
  request(API_GET_ATHLETE_ROUTE()).then(res => res as IAthleteResponse)

export interface UserStats {
  id: number
  email: string
  name: string
  createdAt: string
  currentPeriodEnd: number
  currentPeriodStart: number
  status: SubscriptionStatus
  type: SubscriptionType | string
  payment: 'stripe' | 'apple' | 'android'
  profilePictureUrl: string | null
  spentOnDropIns: number
  numberOfJoinedClasses: number
}

export const fetchUserStats = async (): Promise<UserStats[]> =>
  request(API_GET_USER_STATS()).then(res => res as UserStats[])

/**
 * Update athlete
 */

export type IUpdateAthleteBody = Partial<IAthleteResponse>

export const fetchUpdateAthlete = async (
  id: TId,
  body: IUpdateAthleteBody,
): Promise<void> => request(API_UPDATE_ATHLETE_ROUTE(), body).then(noop)

/**
 * Delete athlete
 */

export const fetchDeleteAthlete = async (id: TId): Promise<void> =>
  request(API_DELETE_ATHLETE_ROUTE(id)).then(noop)
