import { IInputTypeProps, ISelectOption } from './Input'
import { EMonth } from '../../../types/user'
import { isOnBrowser } from '../../../services/browser'

// Types

interface IKeyValue {
  key: string
  value: string
}

// Helper functions

export const getMapOptionsFromEnum = (e: object): IKeyValue[] =>
  Object.entries(e).map(([key, value]) => ({
    key,
    value,
  }))

/**
 * Get key, value mappings in an array from the provided TS enum
 * @param {object} e an enum with keys and values
 */
export const getOptionsFromEnum = (e: object): IKeyValue[] =>
  Object.entries(e).map(([, value]) => ({
    key: value,
    value,
  }))

/**
 * Helper functions to determine if a form should be disabled
 * Disabled means that the user should not be able to submit it
 *
 * Effectively, go through the validations on all input. For each input, if it
 * is required but has no field, then the form should be disabled
 *
 * @param inputTypes map of input types from names to validations
 */
export const isDisabledGenerator = (inputTypes: {
  [s: string]: IInputTypeProps
}) => (state: { pending: boolean; [s: string]: any }): boolean => {
  if (state.pending) return true

  const names = Object.keys(inputTypes)
  for (const name of names) {
    if (name === 'pending') continue
    const isRequired: boolean = inputTypes[name].required
    if (isRequired) {
      const currentValue = state[name]
      if (!currentValue) return true
    }
  }

  return false
}

export const handleChangeGenerator = (self: React.Component<{}>) => (
  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
): void => {
  event.preventDefault()
  const { name, value } = event.target
  const updates = { [name]: value }
  self.setState(updates)
}

/**
 * Simulate sending an HTTP request which just throws an error after 1 second
 * Useful for testing state updates while developing
 * @param msg optional error message to throw, default 'Not yet implemented'
 * @throws error after 1 second
 */
export const sendMockRequest = (msg?: string): Promise<never> =>
  new Promise((_, reject) =>
    setTimeout(() => reject(new Error(msg || 'Not yet implemented')), 1000),
  )

// Constants

export const defaultErrorMsg = 'Something went wrong!'

/**
 * Filter out all keys which have undefined or null values
 * @param obj any KV mapping
 */
export const filterObject = (obj: Record<string, any>): Record<string, any> => {
  const keys: string[] = Object.keys(obj)
  const filteredKeys = keys.filter(key => {
    const value = obj[key]
    return value !== undefined && value !== null
  })
  const filteredObj: Record<string, any> = {}
  filteredKeys.forEach(key => (filteredObj[key] = obj[key]))
  return filteredObj
}

const monthToDaysMap: Record<string, number> = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
}

export const getDaysInMonth = (month?: EMonth): number =>
  monthToDaysMap[month || ''] || 31

// TODO only change options if number of days in month changes
export const getDaySelectOptions = (
  days: number,
): Array<ISelectOption<number>> => {
  if (days < 0) return []
  const options = []
  for (let i = 1; i <= days; i++) {
    options.push({ key: `${i}`, value: i })
  }
  return options
}

export const getYearSelectOptions = (): Array<ISelectOption<number>> => {
  const minYear = 1930
  const maxYear = 2010
  const years = []
  for (let i = minYear; i <= maxYear; i += 1) {
    years.push(i)
  }
  return years.map((year: number) => ({ key: `${year}`, value: year }))
}

export const cleanPhoneNumber = (phone: string): number =>
  Number.parseInt(phone.replace(/\D/g, ''), 10)

export const getQueryParams = (): URLSearchParams | undefined => {
  if (!isOnBrowser()) return undefined
  const href = window.location.href
  const url = new URL(href)
  return new URLSearchParams(url.search)
}
