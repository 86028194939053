import { createReducer } from 'typesafe-actions'
import {
  UserPaymentActions,
  onFetchUserPayment,
  UserPaymentResponse,
} from '../../actions'

interface UserPaymentState {
  error: any
  isFetching: boolean
  payment: UserPaymentResponse | undefined
}

export const defaultState: UserPaymentState = {
  error: null,
  isFetching: false,
  payment: undefined,
}

const reducer = createReducer<UserPaymentState, UserPaymentActions>(
  defaultState,
)
  .handleAction(onFetchUserPayment.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchUserPayment.success, (_, action) => ({
    error: null,
    isFetching: false,
    payment: action.payload,
  }))
  .handleAction(onFetchUserPayment.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
