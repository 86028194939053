import { combineReducers, AnyAction } from 'redux'
import { StateType } from 'typesafe-actions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import entities from './entities'
import ui from './ui'

const entitiesPersistConfig = {
  key: 'entities',
  storage,
}

const uiPersistConfig = {
  key: 'ui',
  storage,
}

const persistConfig = {
  key: 'root',
  storage,
}

const appReducer = combineReducers({
  entities: persistReducer(entitiesPersistConfig, entities),
  ui: persistReducer(uiPersistConfig, ui),
})

interface State {
  entities: any
  ui: any
}

const rootReducer = (state: State, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    state = {
      entities: undefined,
      ui: undefined,
    }
  }

  return appReducer(state, action)
}

export type RootState = StateType<typeof rootReducer>

export default persistReducer(persistConfig, rootReducer)
