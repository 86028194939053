import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchWorkoutById,
  WorkoutByIdActions,
  WorkoutByIdResponse,
} from '../actions'

const fetchWorkoutById: Epic = (
  action$: ActionsObservable<WorkoutByIdActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchWorkoutById.request)),
    mergeMap(({ payload }) =>
      api$(Methods.get, `/workout/${payload}`).pipe(
        map((workout: any) =>
          onFetchWorkoutById.success(workout as WorkoutByIdResponse),
        ),
        catchError(err => of(onFetchWorkoutById.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchWorkoutById)
