// ignore this ts error
import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'

import { setGlobalReduxStore } from './src/services/reduxService'
import { configureStore } from './src/store'
import { TaxRateProvider } from './src/context/TaxRateContext'

const metaDataContent = {
  title: process.env.GATSBY_CLIENT_NAME,
  description:
    process.env.GATSBY_OG_DESCRIPTION ||
    process.env.GATSBY_CLIENT_NAME + ' Digital',
  image: process.env.GATSBY_OG_IMAGE || process.env.GATSBY_CLIENT_LOGO,
}

const queryClient = new QueryClient()

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const storeObj = configureStore(() => {
    setGlobalReduxStore(storeObj.store, storeObj.persistor)
  })

  return (
    <Provider store={storeObj.store}>
      <QueryClientProvider client={queryClient}>
        <TaxRateProvider>
          <Helmet>
            <meta property="og:title" content={metaDataContent.title} />
            <meta
              property="og:description"
              content={metaDataContent.description}
            />
            <meta property="og:image" content={metaDataContent.image} />
          </Helmet>
          {element}
        </TaxRateProvider>
      </QueryClientProvider>
    </Provider>
  )
}
