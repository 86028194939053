import { combineReducers } from 'redux'
import subscriptionsReducer from './fetchSubscriptionsReducer'
import athleteProfileReducer from './fetchAthleteProfileReducer'
import userMetadataReducer from './fetchUserMetadataReducer'
import userPaymentReducer from './fetchUserPaymentReducer'
import workoutsReducer from './fetchWorkoutsReducer'
import likedWorkoutsReducer from './fetchLikedWorkoutsReducer'
import completedWorkoutsReducer from './fetchCompletedWorkoutsReducer'
import scheduledWorkoutsReducer from './fetchScheduledWorkoutsReducer'
import workoutByIdReducer from './fetchWorkoutByIdReducer'

export default combineReducers({
  subscriptionsReducer,
  athleteProfileReducer,
  userMetadataReducer,
  userPaymentReducer,
  workoutsReducer,
  likedWorkoutsReducer,
  completedWorkoutsReducer,
  scheduledWorkoutsReducer,
  workoutByIdReducer,
})
