import { WorkoutResponse } from './../../actions/fetchWorkouts'
import { createReducer } from 'typesafe-actions'
import { LikedWorkoutActions, onFetchLikedWorkouts } from '../../actions'

interface LikedWorkoutsState {
  error: any
  isFetching: boolean
  likedWorkouts: WorkoutResponse[]
}

export const defaultState: LikedWorkoutsState = {
  error: null,
  isFetching: false,
  likedWorkouts: [],
}

const reducer = createReducer<LikedWorkoutsState, LikedWorkoutActions>(
  defaultState,
)
  .handleAction(onFetchLikedWorkouts.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchLikedWorkouts.success, (_, action) => ({
    error: null,
    isFetching: false,
    likedWorkouts: action.payload,
  }))
  .handleAction(onFetchLikedWorkouts.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
