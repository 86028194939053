import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchUserPayment,
  UserPaymentActions,
  UserPaymentResponse,
} from '../actions'

const fetchUserPayment: Epic = (
  action$: ActionsObservable<UserPaymentActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchUserPayment.request)),
    mergeMap(() =>
      api$(Methods.get, '/subscription/payment').pipe(
        map((payment: any) =>
          onFetchUserPayment.success(payment as UserPaymentResponse),
        ),
        catchError(err => of(onFetchUserPayment.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchUserPayment)
