import { ActionType, createAsyncAction } from 'typesafe-actions'

export enum SubscriptionType {
  monthly = 'monthly',
  annual = 'annual',
}

export interface UserMetaResponse {
  subscriptionId: string
  customerId: string
  paymentMethodId: string
  priceId: string
  productId: string
  currentPeriodStart: number
  platform: string
  currentPeriodEnd: number
  type: SubscriptionType
  status: string
}

export const onFetchUserMetadata = createAsyncAction(
  'user/FETCH_USER_METADATA',
  'user/FETCH_USER_METADATA_SUCCESS',
  'user/FETCH_USER_METADATA_FAILURE',
)<void, UserMetaResponse, Error>()

export type UserMetadataActions = ActionType<typeof onFetchUserMetadata>
