import { onUserLogout } from '../redux/actions'
import { store } from './reduxService'

/**
 * Log the user out and redirect them to the screen to log in or register
 */
export const dispatchLogout = () => {
  try {
    store.dispatch(onUserLogout())
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.log(error)
  }
}
