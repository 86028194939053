import { deprecated, ActionType } from 'typesafe-actions'
const { createStandardAction } = deprecated

import { UserMetaResponse } from '../actions'

export const setUserAccess = createStandardAction('user/SET_USER_ACCESS')<
  UserMetaResponse
>()

export type UserAccessActions = ActionType<typeof setUserAccess>
