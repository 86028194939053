import { ITokenResponse } from './fetch/auth'
import { DateTime } from 'luxon'
import jwt from 'jsonwebtoken'
import { navigate } from 'gatsby'
import axios from 'axios'

import { isOnBrowser } from './browser'
import {
  WORKOUTS_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  TRAINER_SCHEDULE,
  MORE_ROUTE,
} from '../constants/routes'
import { UserType, TId } from '../types'
import { fetchRefreshToken } from './fetch'
import { mapToQueryParamString } from './helpers'
import * as logger from './logger'
import { dispatchLogout } from './logout'
import * as Sentry from '@sentry/react'

/**
 * Keys used in local storage
 */

const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'

/**
 * Check if the site is only for Landing page and route post login accordingly
 */

const DEFAULT_ROUTE =
  process.env.GATSBY_LANDING_PAGE_ONLY_TOGGLE === 'true'
    ? MORE_ROUTE
    : WORKOUTS_ROUTE

/**
 * Types relating to sessions
 */

export enum SubscriptionStatus {
  incomplete = 'incomplete',
  incompleteExpired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  pastDue = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
}

export interface Token {
  id: number
  newUserId: number
  email: string
  exp: number
  iat: number
  type: UserType
  status: SubscriptionStatus
}

let cachedStore: Storage

/**
 * Helper functions
 */

const getStore = (): Storage | undefined => {
  if (cachedStore) return cachedStore
  if (!isOnBrowser()) return undefined
  if (typeof window === 'undefined') return undefined
  if (!window || !window.localStorage) return undefined
  cachedStore = window.localStorage
  return cachedStore
}

export const storeTokenResponse = (
  accessToken: string,
  refreshToken: string,
) => {
  const store = getStore()
  if (store) {
    store.setItem(ACCESS_TOKEN_KEY, accessToken)
    store.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }
}

/**
 * Getter functions
 */

export const getAccessToken = (): string | undefined => {
  const store = getStore()
  return (store && store.getItem(ACCESS_TOKEN_KEY)) || undefined
}

export const getRefreshToken = (): string | undefined => {
  const store = getStore()
  return (store && store.getItem(REFRESH_TOKEN_KEY)) || undefined
}

export const getAccessTokenDecoded = (): Token | undefined => {
  const accessToken = getAccessToken()
  return (accessToken && (jwt.decode(accessToken) as Token)) || undefined
}

const getRefreshTokenDecoded = (): Token | undefined => {
  const refreshToken = getRefreshToken()
  return (refreshToken && (jwt.decode(refreshToken) as Token)) || undefined
}

/**
 * Exported util functions
 */

export const isTrainer = (): boolean => {
  const decoded = getAccessTokenDecoded()
  return Boolean(decoded && decoded.type && decoded.type === UserType.Trainer)
}

export const getId = (): TId | undefined => {
  const decoded = getAccessTokenDecoded()
  if (!decoded) return undefined
  const { newUserId, id } = decoded
  if (!newUserId && !id) {
    logger.log('Invalid access token')
    logout()
    return undefined
  }

  return newUserId || id
}

export const isAdmin = (): boolean => {
  const envAdminVars = process.env.GATSBY_TRAINER_ADMIN_IDS
  const adminIds =
    typeof envAdminVars === 'string' ? envAdminVars.split(',') : []

  return adminIds.filter(adminId => Number(adminId) === getId()).length === 1
}

export const refreshPage = (params?: Record<string, string>): void => {
  if (typeof window === 'undefined') {
    return
  }

  if (!params) {
    window.location.reload()
    return
  }

  const { href, search } = window.location
  const routeWithQueryParams =
    href + mapToQueryParamString(params, Boolean(search))
  window.location.href = routeWithQueryParams
}

export const redirect = (
  route: string = HOME_ROUTE,
  params?: Record<string, string>,
): void => {
  if (typeof window === 'undefined') return // Can't redirect if not on browser
  const { href } = window.location
  if (href === route) return // There is no need to redirect

  if (!params) {
    navigate(route)
  } else {
    const routeWithQueryParams = route + mapToQueryParamString(params)
    navigate(routeWithQueryParams)
  }
}

interface ILogoutProps {
  message: string
}

export const logout = (props?: ILogoutProps): void => {
  const store = getStore()
  if (!store) return

  dispatchLogout()

  Sentry.configureScope(scope => scope.setUser(null))

  // Remove auth tokens stored locally
  store.removeItem(ACCESS_TOKEN_KEY)
  store.removeItem(REFRESH_TOKEN_KEY)

  if (props) {
    const { message } = props
    return redirect(LOGIN_ROUTE, { message })
  }

  redirect(LOGIN_ROUTE)
}

export const login = (accessToken: string, refreshToken: string): void => {
  const store = getStore()
  if (!store) return

  if (!accessToken) throw new Error('Missing access token')
  if (!refreshToken) throw new Error('Missing refresh token')

  // Store user session credentials
  store.setItem(ACCESS_TOKEN_KEY, accessToken)
  store.setItem(REFRESH_TOKEN_KEY, refreshToken)

  // Redirect the user
  redirect(isTrainer() ? TRAINER_SCHEDULE : DEFAULT_ROUTE)
}

export const loginWithoutRedirect = (
  accessToken: string,
  refreshToken: string,
): void => {
  const store = getStore()
  if (!store) return

  if (!accessToken) throw new Error('Missing access token')
  if (!refreshToken) throw new Error('Missing refresh token')

  // Store user session credentials
  store.setItem(ACCESS_TOKEN_KEY, accessToken)
  store.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

/**
 * Return true if token is expired
 *
 *
 */
const isExpired = (expTime: number): boolean => {
  const currentTime = DateTime.utc().toSeconds()
  return expTime - currentTime < 0
}

export const getToken = async (): Promise<string | undefined> => {
  let accessTokenJwt = getAccessToken() as string
  if (!accessTokenJwt) {
    logout()
    return undefined
  }

  const token = jwt.decode(accessTokenJwt) as Token

  if (isExpired(token.exp)) {
    accessTokenJwt = await refreshAccessToken()
  }

  return accessTokenJwt
}

export const refreshAccessToken = async (): Promise<string> => {
  const currRefreshToken = getRefreshToken() as string
  if (!currRefreshToken) {
    logout()
  }

  const apiClient = axios.create({})
  try {
    const { data } = await apiClient({
      method: 'POST',
      url: `${process.env.GATSBY_API_ROOT}/token`,
      data: {
        refresh_token: currRefreshToken, // eslint-disable-line
      },
      timeout: 150000,
    })

    const {
      access_token: accessToken,
      refresh_token: refreshToken,
    } = data as ITokenResponse

    storeTokenResponse(accessToken, refreshToken)
    return accessToken
  } catch (error) {
    logout()
  }
}

/**
 * Return true if there is an access token and it has not expired
 * Return false if there is no access token
 */
export const isAuthenticated = (): boolean => {
  const accessTokenJwt = getAccessToken()
  return Boolean(accessTokenJwt)
}

/**
 * Return if there is a refresh token and it has expired
 * Return false if there is no refresh token
 */
export const isRefreshTokenExpired = (): boolean => {
  const refreshTokenDecoded = getRefreshTokenDecoded()
  if (!refreshTokenDecoded) return false
  return isExpired(refreshTokenDecoded.exp)
}

export const handleExpiredTokens = (): void => {
  if (!isAuthenticated()) return
  const refreshToken = getRefreshToken()
  if (!refreshToken) return logout()
  if (isRefreshTokenExpired()) return logout()

  // Attempt to refresh the user's access token
  fetchRefreshToken({ refresh_token: refreshToken }) // eslint-disable-line
    .then(({ access_token: accessToken, refresh_token: newRefreshToken }) => {
      loginWithoutRedirect(accessToken, newRefreshToken)
    })
    .catch(err => {
      logger.log(err)
      return logout()
    })
}

/**
 * Return if the user is currently logged in
 *
 * NOTE this will only return a boolean if on browser
 */
export const isLoggedIn = (): boolean | undefined => {
  const store = getStore()
  if (!store) {
    logger.log('store is undefined')
    return undefined
  }

  const accessToken = store.getItem(ACCESS_TOKEN_KEY)
  if (!accessToken) return false

  const userId = getId()
  if (!userId) return false

  const refreshToken = store.getItem(REFRESH_TOKEN_KEY)
  return Boolean(refreshToken)
}

export const redirectIfLoggedIn = (route: string = HOME_ROUTE): void => {
  if (!isLoggedIn()) return /* do nothing */
  redirect(route)
}

/**
 * Redirect the user to the specified frontend route if they are not logged in
 *
 * NOTE this does nothing if not called on the browser
 * If no route is provided, redirect to home
 *
 * @param route
 */
export const redirectIfNotLoggedIn = (route: string = HOME_ROUTE): void => {
  const loggedIn = isLoggedIn()
  if (loggedIn !== false) return /* do nothing */
  redirect(route)
}
