export enum ERequestMethod {
  'GET' = 'GET',
  'POST' = 'POST',
  'DELETE' = 'DELETE',
  'HEAD' = 'HEAD',
  'PATCH' = 'PATCH',
  'PUT' = 'PUT',
}

export enum EResponseType {
  'JSON' = 'JSON',
  'TEXT' = 'TEXT',
  'EMPTY' = 'EMPTY',
}

export enum EContentType {
  'JSON' = 'application/json',
  'FORM_DATA' = 'multipart/form-data',
}
