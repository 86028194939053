export enum UserType {
  'Athlete' = 'ATHLETE',
  'Trainer' = 'TRAINER',
}

export enum EAccountType {
  'Athlete' = 'ATHLETE',
  'Trainer' = 'TRAINER',
}

// NOTE these have to be strings for enum operations to work as expected
export enum EMonth {
  'January' = 'January',
  'February' = 'February',
  'March' = 'March',
  'April' = 'April',
  'May' = 'May',
  'June' = 'June',
  'July' = 'July',
  'August' = 'August',
  'September' = 'September',
  'October' = 'October',
  'November' = 'November',
  'December' = 'December',
}

export const monthToNumberMap: { [m in EMonth]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}

export const numberToMonthMap: { [n: number]: EMonth } = {
  1: EMonth.January,
  2: EMonth.February,
  3: EMonth.March,
  4: EMonth.April,
  5: EMonth.May,
  6: EMonth.June,
  7: EMonth.July,
  8: EMonth.August,
  9: EMonth.September,
  10: EMonth.October,
  11: EMonth.November,
  12: EMonth.December,
}

export interface IBirthday {
  year: number | undefined
  day: number | undefined
  month: EMonth | undefined
}

export enum EGender {
  'male' = 'male',
  'female' = 'female',
}

export enum EState {
  'AL' = 'Alabama',
  'AK' = 'Alaska',
  'AS' = 'American Samoa',
  'AZ' = 'Arizona',
  'AR' = 'Arkansas',
  'CA' = 'California',
  'CO' = 'Colorado',
  'CT' = 'Connecticut',
  'DE' = 'Delaware',
  'DC' = 'District Of Columbia',
  'FM' = 'Federated States Of Micronesia',
  'FL' = 'Florida',
  'GA' = 'Georgia',
  'GU' = 'Guam',
  'HI' = 'Hawaii',
  'ID' = 'Idaho',
  'IL' = 'Illinois',
  'IN' = 'Indiana',
  'IA' = 'Iowa',
  'KS' = 'Kansas',
  'KY' = 'Kentucky',
  'LA' = 'Louisiana',
  'ME' = 'Maine',
  'MH' = 'Marshall Islands',
  'MD' = 'Maryland',
  'MA' = 'Massachusetts',
  'MI' = 'Michigan',
  'MN' = 'Minnesota',
  'MS' = 'Mississippi',
  'MO' = 'Missouri',
  'MT' = 'Montana',
  'NE' = 'Nebraska',
  'NV' = 'Nevada',
  'NH' = 'New Hampshire',
  'NJ' = 'New Jersey',
  'NM' = 'New Mexico',
  'NY' = 'New York',
  'NC' = 'North Carolina',
  'ND' = 'North Dakota',
  'MP' = 'Northern Mariana Islands',
  'OH' = 'Ohio',
  'OK' = 'Oklahoma',
  'OR' = 'Oregon',
  'PW' = 'Palau',
  'PA' = 'Pennsylvania',
  'PR' = 'Puerto Rico',
  'RI' = 'Rhode Island',
  'SC' = 'South Carolina',
  'SD' = 'South Dakota',
  'TN' = 'Tennessee',
  'TX' = 'Texas',
  'UT' = 'Utah',
  'VT' = 'Vermont',
  'VI' = 'Virgin Islands',
  'VA' = 'Virginia',
  'WA' = 'Washington',
  'WV' = 'West Virginia',
  'WI' = 'Wisconsin',
  'WY' = 'Wyoming',
}
