import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchUserMetadata,
  UserMetadataActions,
  UserMetaResponse,
  setUserAccess,
} from '../actions'
import { store } from '../../services/reduxService'

const fetchUserMetadata: Epic = (
  action$: ActionsObservable<UserMetadataActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchUserMetadata.request)),
    mergeMap(() =>
      api$(Methods.get, '/user').pipe(
        map((userMetadata: any) => {
          const userMeta = userMetadata as UserMetaResponse
          store.dispatch(setUserAccess(userMeta))
          return onFetchUserMetadata.success(userMeta)
        }),
        catchError(err => of(onFetchUserMetadata.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchUserMetadata)
