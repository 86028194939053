import { DateTime } from 'luxon'
import { UserMetaResponse } from './../redux/actions'

/**
 * Return true if user's subscriptions is active
 * OR
 * if user's subscription is cancelled and current_period_end is in future
 */

export const isUserSubscribed = (meta: UserMetaResponse): boolean => {
  const { status, currentPeriodEnd } = meta


  if (status === 'active' || status === 'trialing') {
    return true
  }

  if (status === 'canceled') {
    // check billing period
    const currentTime = DateTime.utc().toSeconds()
    return currentTime > currentPeriodEnd ? false : true
  }

  return false
}
