import { createReducer } from 'typesafe-actions'
import {
  AthleteProfileActions,
  onFetchAthleteProfile,
  AthleteProfileResponse,
} from '../../actions'

interface AthleteProfileState {
  error: any
  isFetching: boolean
  profile: AthleteProfileResponse | undefined
}

export const defaultState: AthleteProfileState = {
  error: null,
  isFetching: false,
  profile: undefined,
}

const reducer = createReducer<AthleteProfileState, AthleteProfileActions>(
  defaultState,
)
  .handleAction(onFetchAthleteProfile.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchAthleteProfile.success, (_, action) => ({
    error: null,
    isFetching: false,
    profile: action.payload,
  }))
  .handleAction(onFetchAthleteProfile.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
