import request from '../request'
import {
  API_DELETE_ATHLETE_ROUTE,
  API_GET_ATHLETE_ROUTE,
  API_UPDATE_ATHLETE_ROUTE,
  API_FETCH_USER_BY_ID,
} from '../apiRoutes'
import { TId, EState, EGender } from '../../types'
import { noop } from '../helpers'

/**
 * Get athlete
 */

export interface IAthleteResponse {
  birthday?: string
  city?: string
  createdAt: string
  email: string
  firstName: string
  gender?: EGender
  id: TId
  lastName: string
  phoneNumber?: string
  state?: EState
  updatedAt: string
  zipcode?: number
}

export const fetchUserById = async (id: TId): Promise<IAthleteResponse> =>
  request(API_FETCH_USER_BY_ID(id)).then(res => res as IAthleteResponse)

export const fetchAthlete = async (id: TId): Promise<IAthleteResponse> =>
  request(API_GET_ATHLETE_ROUTE(id)).then(res => res as IAthleteResponse)

/**
 * Update athlete
 */

export type IUpdateAthleteBody = Partial<IAthleteResponse>

export const fetchUpdateAthlete = async (
  id: TId,
  body: IUpdateAthleteBody,
): Promise<void> => request(API_UPDATE_ATHLETE_ROUTE(id), body).then(noop)

/**
 * Delete athlete
 */

export const fetchDeleteAthlete = async (id: TId): Promise<void> =>
  request(API_DELETE_ATHLETE_ROUTE(id)).then(noop)
