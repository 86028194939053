import { ActionType, createAsyncAction } from 'typesafe-actions'
import { WorkoutResponse } from './fetchWorkouts'

export const onFetchLikedWorkouts = createAsyncAction(
  'workouts/FETCH_LIKED_WORKOUTS',
  'workouts/FETCH_LIKED_WORKOUTS_SUCCESS',
  'workouts/FETCH_LIKED_WORKOUTS_FAILURE',
)<void, WorkoutResponse[], Error>()

export type LikedWorkoutActions = ActionType<typeof onFetchLikedWorkouts>
