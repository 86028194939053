import { createReducer } from 'typesafe-actions'
import { UserAccessActions, setUserAccess } from '../../actions'
import { isUserSubscribed } from '../../../services/userAccess'

export interface State {
  userHasAccess: boolean
}

export const defaultState: State = {
  userHasAccess: false,
}

const reducer = createReducer<State, UserAccessActions>(
  defaultState,
).handleAction(setUserAccess, (_, action) => {
  const userMeta = action.payload
  const userHasAccess = isUserSubscribed(userMeta)
  return {
    userHasAccess,
  }
})

export default reducer
