import { WorkoutResponse } from './../../actions/fetchWorkouts'
import { createReducer } from 'typesafe-actions'
import { WorkoutActions, onFetchWorkouts } from '../../actions'

interface WorkoutState {
  error: any
  isFetching: boolean
  workouts: WorkoutResponse[]
}

export const defaultState: WorkoutState = {
  error: null,
  isFetching: false,
  workouts: [],
}

const reducer = createReducer<WorkoutState, WorkoutActions>(defaultState)
  .handleAction(onFetchWorkouts.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchWorkouts.success, (_, action) => ({
    error: null,
    isFetching: false,
    workouts: action.payload,
  }))
  .handleAction(onFetchWorkouts.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
