import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchAthleteProfile,
  AthleteProfileActions,
  AthleteProfileResponse,
} from '../actions'

const fetchAthleteProfile: Epic = (
  action$: ActionsObservable<AthleteProfileActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchAthleteProfile.request)),
    mergeMap(() =>
      api$(Methods.get, '/athlete-profile').pipe(
        map((profile: any) =>
          onFetchAthleteProfile.success(profile as AthleteProfileResponse),
        ),
        catchError(err => of(onFetchAthleteProfile.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchAthleteProfile)
