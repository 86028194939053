import { ActionType, createAsyncAction } from 'typesafe-actions'
import { WorkoutResponse } from './fetchWorkouts'

export interface ScheduledWorkoutResponse {
  startDate: string
  workout: WorkoutResponse
}

export const onFetchScheduledWorkouts = createAsyncAction(
  'workouts/FETCH_SCHEDULED_WORKOUTS',
  'workouts/FETCH_SCHEDULED_WORKOUTS_SUCCESS',
  'workouts/FETCH_SCHEDULED_WORKOUTS_FAILURE',
)<void, ScheduledWorkoutResponse[], Error>()

export type ScheduledWorkoutsActions = ActionType<
  typeof onFetchScheduledWorkouts
>
