import {
  API_CREATE_WORKOUT_ROUTE,
  API_UPLOAD_WORKOUT_VIDEO_ROUTE,
  API_GET_WORKOUT_ROUTE,
  API_DELETE_WORKOUT_ROUTE,
  API_UPDATE_WORKOUT_ROUTE,
  API_UPDATE_WORKOUT_VIDEO_ROUTE,
  API_GET_LIVE_WORKOUTS_ROUTE,
  API_DELETE_WORKOUT_VIDEO_ROUTE,
  API_CREATE_LIVE_WORKOUT_ROUTE,
  API_EDIT_LIVE_WORKOUT_ROUTE,
  API_DELETE_LIVE_WORKOUT_ROUTE,
  API_SIGN_UP_LIVE_WORKOUT_ROUTE,
  API_GET_LIVE_WORKOUT_DETAIL,
  API_GET_LIVE_CONFIG,
  API_GET_ALL_LIVE_WORKOUTS_ROUTE,
} from '../apiRoutes'
import request from '../../services/request'
import {
  EContentType,
  EResponseType,
  TId,
  EWorkoutType,
  EWorkoutIntensity,
  EMuscleGroups,
  EEquipment,
  EWorkoutFormat,
  IWorkout,
  ILiveWorkout,
  LiveConfig,
} from '../../types'
import * as logger from '../../util/logger'
import { noop } from '../helpers'

const GATSBY_API_BASE_CLIENT =
  process.env.GATSBY_API_BASE_CLIENT === 'true' ? true : false

export interface IWorkoutBody {
  title: string
  description: string
  type: EWorkoutType
  intensity: EWorkoutIntensity
  muscles: EMuscleGroups[]
  equipment: EEquipment
  format: EWorkoutFormat
  length: number
  published?: boolean
}

interface ICreateWorkoutResponse {
  status: string
  workoutId: TId
}

export const fetchCreateWorkout = async (
  body: IWorkoutBody,
): Promise<ICreateWorkoutResponse> =>
  request(API_CREATE_WORKOUT_ROUTE, body).then(
    res => res as ICreateWorkoutResponse,
  )

export const fetchWorkout = async (id: TId): Promise<IWorkout> =>
  request(API_GET_WORKOUT_ROUTE(id)).then(res => res as IWorkout)

export const fetchUploadWorkoutVideo = async (
  id: TId,
  data: FormData,
): Promise<void> =>
  request(API_UPLOAD_WORKOUT_VIDEO_ROUTE(id), data, {
    responseType: EResponseType.JSON,
    contentType: EContentType.FORM_DATA,
  }).then(res => {
    logger.log(res)
    return
  })

interface IUpdateWorkoutVideoBody {
  title?: string
  description?: string
  sequence?: number
}

export const fetchUpdateWorkoutVideo = async (
  workoutId: TId,
  videoId: TId,
  body: IUpdateWorkoutVideoBody,
): Promise<void> =>
  request(API_UPDATE_WORKOUT_VIDEO_ROUTE(workoutId, videoId), body).then(noop)

export interface EditWorkoutBody {
  title?: string
  description?: string
  descriptionTwo?: string
  spotifyLink?: string
  type?: string
  intensity?: string
  equipment?: string
  format?: string
  published?: boolean
  tags?: string[]
  thumbnail?: string
  trainerId?: number
  videoUrl?: string
  length?: number
  muscles?: string[]
}

/**
 * Update workout. NOTE this does not update workout vidoes.
 *
 * @param id of the workout
 * @param body new workout information
 */
export const fetchUpdateWorkout = async (
  id: TId,
  body: EditWorkoutBody,
): Promise<void> => request(API_UPDATE_WORKOUT_ROUTE(id), body).then(noop)

/**
 * Permanently delete a workout from the database
 *
 * @param id of the workout
 */
export const fetchDeleteWorkout = async (id: TId): Promise<void> =>
  request(API_DELETE_WORKOUT_ROUTE(id)).then(noop)

export const fetchDeleteWorkoutStep = async (
  workoutId: TId,
  videoId: TId,
): Promise<void> =>
  request(API_DELETE_WORKOUT_VIDEO_ROUTE(workoutId, videoId)).then(noop)

// LIVE Workouts
export const fetchLiveWorkouts = async (): Promise<ILiveWorkout[]> => {
  if (GATSBY_API_BASE_CLIENT) {
    return request(API_GET_LIVE_WORKOUTS_ROUTE(), {}, {}, true).then(
      res => res as ILiveWorkout[],
    )
  } else {
    return request(API_GET_LIVE_WORKOUTS_ROUTE()).then(
      res => res as ILiveWorkout[],
    )
  }
}

export const fetchAllLiveWorkouts = async (): Promise<ILiveWorkout[]> => {
  if (GATSBY_API_BASE_CLIENT) {
    return request(API_GET_ALL_LIVE_WORKOUTS_ROUTE(), {}, {}, true).then(
      res => res as ILiveWorkout[],
    )
  } else {
    return request(API_GET_ALL_LIVE_WORKOUTS_ROUTE()).then(
      res => res as ILiveWorkout[],
    )
  }
}

export const fetchLiveConfig = async (): Promise<LiveConfig> =>
  request(API_GET_LIVE_CONFIG(), {}, {}, true).then(res => res as LiveConfig)

export const fetchLiveWorkoutDetail = async (
  id: number,
): Promise<ILiveWorkout> => {
  if (GATSBY_API_BASE_CLIENT) {
    return request(API_GET_LIVE_WORKOUT_DETAIL(id), {}, {}, true).then(
      res => res as ILiveWorkout,
    )
  } else {
    return request(API_GET_LIVE_WORKOUT_DETAIL(id)).then(
      res => res as ILiveWorkout,
    )
  }
}

export const createLiveWorkout = async (
  data: ILiveWorkout,
): Promise<ILiveWorkout> =>
  request(API_CREATE_LIVE_WORKOUT_ROUTE(), data).then(
    res => res as ILiveWorkout,
  )

export const editLiveWorkout = async (
  data: ILiveWorkout,
  id: number,
): Promise<ILiveWorkout> =>
  request(API_EDIT_LIVE_WORKOUT_ROUTE(id), data).then(
    res => res as ILiveWorkout,
  )

export const deleteLiveWorkout = async (id: number): Promise<ILiveWorkout> =>
  request(API_DELETE_LIVE_WORKOUT_ROUTE(id)).then(res => res as ILiveWorkout)

export const signupLiveWorkout = async (id: number): Promise<ILiveWorkout> =>
  request(API_SIGN_UP_LIVE_WORKOUT_ROUTE(id)).then(res => res as ILiveWorkout)
