import jwt from 'jsonwebtoken'
import { navigate } from 'gatsby'

import { dispatchLogout } from './../services/logout'
import { isOnBrowser } from './browser'
import { PROFILE_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from '../constants/routes'
import { EAccountType, TId } from '../types'
import { fetchRefreshToken, ITrainerResponse } from './fetch'
import { mapToQueryParamString } from './helpers'
import * as logger from './logger'
import { IAthleteResponse } from './fetch/athletes'
/**
 * Keys used in local storage
 */

const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'
const USER_PROFILE_DATA = 'userProfileData'

/**
 * Types relating to sessions
 */

interface IToken {
  email: string
  exp: number
  id: number
  newUserId: number
  type: EAccountType
}

let cachedStore: Storage

/**
 * Helper functions
 */

const getStore = (): Storage | undefined => {
  if (cachedStore) return cachedStore
  if (!isOnBrowser()) return undefined
  if (typeof window === 'undefined') return undefined
  if (!window || !window.localStorage) return undefined
  cachedStore = window.localStorage
  return cachedStore
}

/**
 * Getter functions
 */

export const getStorageItem = (key: string): string => {
  const store = getStore()
  return (store && store.getItem(key)) || undefined
}

export const getAccessToken = (): string | undefined => {
  const store = getStore()
  return (store && store.getItem(ACCESS_TOKEN_KEY)) || undefined
}

export const getRefreshToken = (): string | undefined => {
  const store = getStore()
  return (store && store.getItem(REFRESH_TOKEN_KEY)) || undefined
}

export const getAccessTokenDecoded = (): IToken | undefined => {
  const accessToken = getAccessToken()
  return (accessToken && (jwt.decode(accessToken) as IToken)) || undefined
}

const getRefreshTokenDecoded = (): IToken | undefined => {
  const refreshToken = getRefreshToken()
  return (refreshToken && (jwt.decode(refreshToken) as IToken)) || undefined
}

export const getClientName = (): string | undefined => {
  if (process.env.GATSBY_CLIENT_NAME === 'Fuse45') {
    return 'fuse45'
  }
  if (process.env.GATSBY_CLIENT_NAME === 'Banana Skirt Productions') {
    return 'bsp'
  }
  if (process.env.GATSBY_CLIENT_NAME === 'Gorilla Bow') {
    return 'gorilla'
  }
  if (process.env.GATSBY_CLIENT_NAME === 'Board30') {
    return 'board30'
  }

  if (process.env.GATSBY_CLIENT_NAME === 'Mile High Run Club') {
    return 'mhrc'
  }

  if (process.env.GATSBY_CLIENT_NAME === 'shannon') {
    return 'shannon'
  }

  if (process.env.GATSBY_CLIENT_NAME === 'Solin') {
    return 'solin'
  }

  return 'solin'
}

/**
 * Exported util functions
 */

export const isTrainer = (): boolean => {
  const decoded = getAccessTokenDecoded()
  return Boolean(
    decoded && decoded.type && decoded.type === EAccountType.Trainer,
  )
}

export const getId = (): TId | undefined => {
  const decoded = getAccessTokenDecoded()
  if (!decoded) return undefined
  const { newUserId, id } = decoded
  if (!newUserId && !id) {
    logger.log('Invalid access token')
    logout()
    return undefined
  }

  return newUserId || id
}

export const isAdmin = (): boolean => {
  const envAdminVars = process.env.GATSBY_TRAINER_ADMIN_IDS
  const adminIds =
    typeof envAdminVars === 'string' ? envAdminVars.split(',') : []

  return adminIds.filter(adminId => Number(adminId) === getId()).length === 1
}

export const refreshPage = (params?: Record<string, string>): void => {
  if (typeof window === 'undefined') {
    return
  }

  if (!params) {
    window.location.reload()
    return
  }

  const { href, search } = window.location
  const routeWithQueryParams =
    href + mapToQueryParamString(params, Boolean(search))
  window.location.href = routeWithQueryParams
}

export const redirect = (
  route: string = HOME_ROUTE,
  params?: Record<string, string>,
): void => {
  if (typeof window === 'undefined') return // Can't redirect if not on browser
  const { href } = window.location
  if (href === route) return // There is no need to redirect

  if (!params) {
    navigate(route)
  } else {
    const routeWithQueryParams = route + mapToQueryParamString(params)
    navigate(routeWithQueryParams)
  }
}

interface ILogoutProps {
  message: string
}

export const logout = (props?: ILogoutProps, redirectTo?: string): void => {
  console.log('logging out from util')
  const store = getStore()
  if (!store) return

  dispatchLogout()

  // Remove auth tokens stored locally
  store.removeItem(ACCESS_TOKEN_KEY)
  store.removeItem(REFRESH_TOKEN_KEY)
  store.removeItem(USER_PROFILE_DATA)

  if (props) {
    const { message } = props
    return redirect(LOGIN_ROUTE, { message })
  }

  redirect(redirectTo || LOGIN_ROUTE)
}

export const login = (
  accessToken: string,
  refreshToken: string,
  shouldNotRedirectToProfile?: boolean,
): void => {
  const store = getStore()
  if (!store) return

  if (!accessToken) throw new Error('Missing access token')
  if (!refreshToken) throw new Error('Missing refresh token')

  // Store user session credentials
  store.setItem(ACCESS_TOKEN_KEY, accessToken)
  store.setItem(REFRESH_TOKEN_KEY, refreshToken)

  // Redirect the user

  if (!shouldNotRedirectToProfile) {
    redirect(PROFILE_ROUTE)
  }
}

export const storeUserData = (data: IAthleteResponse | ITrainerResponse) => {
  const store = getStore()
  if (!store) return

  store.setItem(USER_PROFILE_DATA, JSON.stringify(data))
}

export const removeUserData = () => {
  const store = getStore()
  if (!store) return
  store.removeItem(USER_PROFILE_DATA)
}

export const getUserData = () => {
  const store = getStore()
  if (!store) return

  const data = store && store.getItem(USER_PROFILE_DATA)
  return data ? JSON.parse(data) : undefined
}

const isExpired = (expTime?: number): boolean => {
  if (!expTime) return true
  const currTime = Date.now() / 1000
  return currTime >= expTime
}

/**
 * Return if there is an access token and it has expired
 * Return false if there is no access token
 */
export const isAccessTokenExpired = (): boolean => {
  const accessTokenDecoded = getAccessTokenDecoded()
  if (!accessTokenDecoded) return false
  return isExpired(accessTokenDecoded.exp)
}

/**
 * Return if there is a refresh token and it has expired
 * Return false if there is no refresh token
 */
const isRefreshTokenExpired = (): boolean => {
  const refreshTokenDecoded = getRefreshTokenDecoded()
  if (!refreshTokenDecoded) return false
  return isExpired(refreshTokenDecoded.exp)
}

export const handleExpiredTokens = async () => {
  if (!isAccessTokenExpired()) return
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    console.log('no refresh toke')
    return logout()
  }
  if (isRefreshTokenExpired()) {
    console.log('refresh token expired')
    return logout()
  }

  logger.log('Refreshing user access token')

  try {
    const {
      access_token: accessToken,
      refresh_token: newRefreshToken,
    } = await fetchRefreshToken({ refresh_token: refreshToken }) // eslint-disable-line
    login(accessToken, newRefreshToken, true)
  } catch (e) {
    logger.log(e)
    return logout()
  }
}

/**
 * Return if the user is currently logged in
 *
 * NOTE this will only return a boolean if on browser
 */
export const isLoggedIn = (): boolean | undefined => {
  const store = getStore()
  if (!store) {
    logger.log('store is undefined')
    return undefined
  }

  const accessToken = store.getItem(ACCESS_TOKEN_KEY)
  if (!accessToken) return false

  const userId = getId()
  if (!userId) return false

  const refreshToken = store.getItem(REFRESH_TOKEN_KEY)
  return Boolean(refreshToken)
}

export const redirectIfLoggedIn = (route: string = HOME_ROUTE): void => {
  if (!isLoggedIn()) return /* do nothing */
  redirect(route)
}

/**
 * Redirect the user to the specified frontend route if they are not logged in
 *
 * NOTE this does nothing if not called on the browser
 * If no route is provided, redirect to home
 *
 * @param route
 */
export const redirectIfNotLoggedIn = (route: string = HOME_ROUTE): void => {
  const loggedIn = isLoggedIn()
  if (loggedIn !== false) return /* do nothing */
  redirect(route)
}
