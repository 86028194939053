import { createReducer } from 'typesafe-actions'
import {
  WorkoutByIdActions,
  onFetchWorkoutById,
  WorkoutByIdResponse,
} from '../../actions'

interface WorkoutByIdState {
  error: any
  isFetching: boolean
  workout: WorkoutByIdResponse | undefined
}

export const defaultState: WorkoutByIdState = {
  error: null,
  isFetching: false,
  workout: undefined,
}

const reducer = createReducer<WorkoutByIdState, WorkoutByIdActions>(
  defaultState,
)
  .handleAction(onFetchWorkoutById.request, (state, action) => {
    if (state.workout && state.workout.id === action.payload) {
      // same workout being fetched
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    return {
      error: null,
      isFetching: true,
      workout: undefined,
    }
  })
  .handleAction(onFetchWorkoutById.success, (_, action) => ({
    error: null,
    isFetching: false,
    workout: action.payload,
  }))
  .handleAction(onFetchWorkoutById.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
