import { ActionType, createAsyncAction } from 'typesafe-actions'
import Stripe from 'stripe'

export interface SubscriptionResponse {
  productId: string
  productDescription: string
  name: string
  currency: string
  metadata: Stripe.Metadata
  priceId: string
  priceMetadata: Stripe.Metadata
  amount: number
}

export const onFetchSubscriptions = createAsyncAction(
  'subscriptions/FETCH_SUBSCRIPTIONS',
  'subscriptions/FETCH_SUBSCRIPTIONS_SUCCESS',
  'subscriptions/FETCH_SUBSCRIPTIONS_FAILURE',
)<void, SubscriptionResponse[], Error>()

export type SubscriptionActions = ActionType<typeof onFetchSubscriptions>
