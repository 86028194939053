import { ActionType, createAsyncAction } from 'typesafe-actions'

export interface UserPaymentResponse {
  cardBrand: string
  expMonth: number
  expYear: number
  lastFour: string
}

export const onFetchUserPayment = createAsyncAction(
  'user/FETCH_USER_PAYMENT',
  'user/FETCH_USER_PAYMENT_SUCCESS',
  'user/FETCH_USER_PAYMENT_FAILURE',
)<void, UserPaymentResponse, Error>()

export type UserPaymentActions = ActionType<typeof onFetchUserPayment>
