import { createReducer } from 'typesafe-actions'
import {
  UserMetadataActions,
  onFetchUserMetadata,
  UserMetaResponse,
} from '../../actions'

interface UserMetadataState {
  error: any
  isFetching: boolean
  userMetadata: UserMetaResponse | undefined
}

export const defaultState: UserMetadataState = {
  error: null,
  isFetching: false,
  userMetadata: undefined,
}

const reducer = createReducer<UserMetadataState, UserMetadataActions>(
  defaultState,
)
  .handleAction(onFetchUserMetadata.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchUserMetadata.success, (_, action) => ({
    error: null,
    isFetching: false,
    userMetadata: action.payload,
  }))
  .handleAction(onFetchUserMetadata.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
