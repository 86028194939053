import { IStringMap, IMap } from '../types/misc'
import { useState, useEffect, useCallback } from 'react'

export const mapToQueryParamString = (
  map: IStringMap,
  isAppend?: boolean,
): string =>
  (isAppend ? '&' : '?') +
  Object.keys(map)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(map[key])}`)
    .join('&')

export const withQueryParams = (route: string, map: IStringMap): string =>
  `${route}${mapToQueryParamString(map)}`

/* eslint-disable */
export const noop = (): void => {
  // Do nothing...
  return
}
/* eslint-enable */

/**
 * Pick a set of props out of an object
 *
 * @param props strings
 */
export const pick = (...props: string[]) => (o: IMap): IMap =>
  props.reduce((a, e) => ({ ...a, [e]: o[e] }), {})

/**
 * Get the full name from an object potentially with firstName and lastName
 * properties
 *
 * @param props.firstName
 * @param props.lastName
 */
export const getName = ({
  firstName,
  lastName,
}: {
  firstName?: string
  lastName?: string
}): string => {
  if (!firstName) return lastName || ''
  if (!lastName) return firstName
  return `${firstName} ${lastName}`
}

export const cleanEmail = (email: string): string => email.trim().toLowerCase()

export const getVoidPromise = (): Promise<void> =>
  new Promise(resolve => resolve())

/**
 * Scroll to the provided ref on the DOM (minus the header height)
 *
 * NOTE/TODO/HACK does not behave the best on Safari or Edge
 *
 * @param ref
 * @returns promise when done scrolling
 */
export const scrollToRef = (
  ref: React.RefObject<HTMLElement>,
): Promise<void> => {
  if (typeof window === 'undefined') return getVoidPromise()
  if (!ref) return getVoidPromise()
  const { current } = ref
  if (!current) return getVoidPromise()
  const offset = current.offsetTop - 54

  try {
    // Try to smooth scroll to the desired location
    window.scroll({
      top: offset,
      left: 0,
      behavior: 'smooth',
    })
    return new Promise(resolve => setTimeout(() => resolve(), 800))
  } catch (err) {
    // If the above function is not defined in the browser, default to this
    // more well-supported function (not smooth scroll)
    window.scrollTo(0, offset < 0 ? 0 : offset)
    return getVoidPromise()
  }
}

interface IWindowSize {
  height: number | undefined
  width: number | undefined
}

export const useWindowSize = (): IWindowSize => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(
    (): IWindowSize => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient],
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect((): (() => void) | undefined => {
    if (!isClient) {
      return
    }

    const handleResize = (): void => {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
