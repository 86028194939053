import request from '../request'
import {
  API_FORGOT_PASSWORD_ROUTE,
  API_RESET_PASSWORD_ROUTE,
  API_CHANGE_TRAINER_PASSWORD_ROUTE,
  API_CHANGE_ATHLETE_PASSWORD_ROUTE,
  API_LOGIN_ROUTE,
  API_REGISTER_ROUTE,
  API_TOKEN_ROUTE,
  API_VALIDATE_EMAIL_ROUTE,
} from '../apiRoutes'
import { noop, cleanEmail } from '../helpers'
import * as session from '../session'

/**
 * Login
 */

interface LoginRequest {
  email: string
  password: string
}

interface LoginResponse {
  access_token: string // eslint-disable-line camelcase
  refresh_token: string // eslint-disable-line camelcase
}

export const fetchLogin = async (
  body: LoginRequest,
): Promise<LoginResponse> => {
  const { email } = body
  const cleanedBody = {
    ...body,
    email: cleanEmail(email),
  }
  return request(API_LOGIN_ROUTE, cleanedBody, {}, true).then(
    json => json as LoginResponse,
  )
}

/**
 * Register
 */

interface IRegisterBody {
  email: string
  password: string
  firstName: string
  lastName: string
  type: 'ATHLETE' | 'TRAINER'
}

interface IRegisterResponse {
  access_token: string // eslint-disable-line camelcase
  refresh_token: string // eslint-disable-line camelcase
}

export const fetchRegister = async (
  body: IRegisterBody,
): Promise<IRegisterResponse> => {
  const { email } = body
  const cleanedBody = {
    ...body,
    email: cleanEmail(email),
  }
  return request(API_REGISTER_ROUTE, cleanedBody).then(
    json => json as IRegisterResponse,
  )
}

/**
 * Refresh token
 */

interface ITokenBody {
  refresh_token: string // eslint-disable-line
}

export interface ITokenResponse {
  access_token: string // eslint-disable-line
  refresh_token: string // eslint-disable-line
}

export const fetchRefreshToken = async (
  body: ITokenBody,
): Promise<ITokenResponse> =>
  request(API_TOKEN_ROUTE, body).then(json => json as ITokenResponse)

/**
 * Forgot password
 */

export const fetchForgotPassword = async (email: string): Promise<void> =>
  request(API_FORGOT_PASSWORD_ROUTE, { email }).then(noop)

/**
 * Reset password
 */

export const fetchResetPassword = async (
  token: string,
  password: string,
): Promise<void> =>
  request(API_RESET_PASSWORD_ROUTE, { password }, { token }).then(noop)

/**
 * Change password
 */

export const fetchChangePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  const id = session.getId()
  const isTrainer = session.isTrainer()

  if (!id || isTrainer === undefined) {
    throw new Error(
      'Invalid request state: user ID and user type must be defined',
    )
  }

  const route = isTrainer
    ? API_CHANGE_TRAINER_PASSWORD_ROUTE
    : API_CHANGE_ATHLETE_PASSWORD_ROUTE

  return request(route(id), { oldPassword, newPassword }).then(noop)
}

type ValidateEmailResponse = any

export const validateEmail = async (body: {
  email: string
}): Promise<ValidateEmailResponse> =>
  request(API_VALIDATE_EMAIL_ROUTE, body, {}, true).then(
    json => json as ValidateEmailResponse,
  )
