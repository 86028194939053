import { ScheduledWorkoutResponse } from './../../actions/'
import { createReducer } from 'typesafe-actions'
import {
  ScheduledWorkoutsActions,
  onFetchScheduledWorkouts,
} from '../../actions'

interface CompletedWorkoutState {
  error: any
  isFetching: boolean
  scheduledWorkouts: ScheduledWorkoutResponse[]
}

export const defaultState: CompletedWorkoutState = {
  error: null,
  isFetching: false,
  scheduledWorkouts: [],
}

const reducer = createReducer<CompletedWorkoutState, ScheduledWorkoutsActions>(
  defaultState,
)
  .handleAction(onFetchScheduledWorkouts.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchScheduledWorkouts.success, (_, action) => ({
    error: null,
    isFetching: false,
    scheduledWorkouts: action.payload,
  }))
  .handleAction(onFetchScheduledWorkouts.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
