import { ActionType, createAsyncAction } from 'typesafe-actions'

export interface CompletedWorkoutResponse {
  id: number
  createdAt: string
  title: string
  type: string
  duration: number
  intensity: string
  firstName: string
  lastName: string
  description: string
  thumbnail: string
}

export const onFetchCompletedWorkouts = createAsyncAction(
  'workouts/FETCH_COMPLETED_WORKOUTS',
  'workouts/FETCH_COMPLETED_WORKOUTS_SUCCESS',
  'workouts/FETCH_COMPLETED_WORKOUTS_FAILURE',
)<void, CompletedWorkoutResponse[], Error>()

export type CompletedWorkoutActions = ActionType<
  typeof onFetchCompletedWorkouts
>
