import request from '../request'
import {
  API_DELETE_TRAINER_ROUTE,
  API_UPDATE_TRAINER_ROUTE,
  API_GET_TRAINER_ROUTE,
} from '../apiRoutes'
import { TId, IWorkout, ITrainer } from '../../types'
import { noop } from '../helpers'

/**
 * Get trainer
 */

export type ITrainerResponse = ITrainer & { workout: IWorkout[] }

export const fetchTrainer = async (id: TId): Promise<ITrainerResponse> =>
  request(API_GET_TRAINER_ROUTE(id)).then(res => res as ITrainerResponse)

/**
 * Update trainer
 */

export type IUpdateTrainerBody = Partial<ITrainerResponse>

export const fetchUpdateTrainer = async (
  id: TId,
  body: IUpdateTrainerBody,
): Promise<ITrainerResponse> =>
  request(API_UPDATE_TRAINER_ROUTE(id), body).then(
    res => res as ITrainerResponse,
  )

/**
 * Delete trainer
 */

export const fetchDeleteTrainer = async (id: TId): Promise<void> =>
  request(API_DELETE_TRAINER_ROUTE(id)).then(noop)
