import { createReducer } from 'typesafe-actions'
import {
  SubscriptionActions,
  onFetchSubscriptions,
  SubscriptionResponse,
} from '../../actions'

interface SubscriptionState {
  error: any
  isFetching: boolean
  subscriptions: SubscriptionResponse[]
}

export const defaultState: SubscriptionState = {
  error: null,
  isFetching: false,
  subscriptions: [],
}

const reducer = createReducer<SubscriptionState, SubscriptionActions>(
  defaultState,
)
  .handleAction(onFetchSubscriptions.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchSubscriptions.success, (_, action) => ({
    error: null,
    isFetching: false,
    subscriptions: action.payload,
  }))
  .handleAction(onFetchSubscriptions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
