import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import { onFetchWorkouts, WorkoutActions, WorkoutResponse } from '../actions'

const fetchWorkouts: Epic = (action$: ActionsObservable<WorkoutActions>) =>
  action$.pipe(
    filter(isActionOf(onFetchWorkouts.request)),
    mergeMap(() =>
      api$(Methods.get, '/v2/workout').pipe(
        map((workouts: any) =>
          onFetchWorkouts.success(workouts as WorkoutResponse[]),
        ),
        catchError(err => of(onFetchWorkouts.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchWorkouts)
