import axios from 'axios'
import { Observable, Observer } from 'rxjs'
import * as session from '../session'

export enum Methods {
  delete = 'delete',
  get = 'get',
  patch = 'patch',
  post = 'post',
  put = 'put',
}

const API_ROOT = process.env.GATSBY_API_ROOT
const X_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME_HEADER

axios.defaults.headers.common['Content-Type'] = 'application/json'

export interface ApiOptions {
  baseUrl?: string
  isBypassAuth?: boolean
  version?: number
}

export const api$ = <T>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Observable<T> =>
  Observable.create(async (obs: Observer<T>) => {
    try {
      const result = await api<T>(method, url, data, options)
      obs.next(result)
      obs.complete()
    } catch (err) {
      if (typeof err === 'object' && err.name === 'TokenRefreshError') {
        obs.complete()
      }

      obs.error(err)
    }
  })

export const api = async <T>(
  method: Methods,
  url: string,
  data?: any,
  options: ApiOptions = {},
): Promise<T> => {
  try {
    const apiClient = axios.create({})
    let parsedUrl = url

    const baseUrl = API_ROOT
    if (!options.baseUrl && baseUrl) {
      parsedUrl = `${baseUrl}${parsedUrl}`
      apiClient.defaults.headers['x-client-name'] = X_CLIENT_NAME
    } else {
      parsedUrl = `${options.baseUrl}${parsedUrl}`
    }

    if (!options.isBypassAuth && session.isAuthenticated()) {
      const token = await session.getToken()
      apiClient.defaults.headers.Authorization = `Bearer ${token}`
    }

    const response = await apiClient({
      method,
      url: parsedUrl,
      data,
      timeout: 15000,
    })

    return response.data
  } catch (err) {
    throw err
  }
}

export const apiWithFormData = async <T>(
  url: string,
  data: FormData,
  options: ApiOptions = {},
): Promise<T> => {
  try {
    const parsedUrl = `${API_ROOT}${url}`

    const headers: any = {
      'x-client-name': X_CLIENT_NAME,
      'Content-Type': 'multipart/form-data',
    }

    if (!options.isBypassAuth && session.isAuthenticated()) {
      const token = await session.getToken()
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post(parsedUrl, data, { headers })

    return response.data
  } catch (error) {
    throw error
  }
}
