import React, { useState, useEffect, ReactNode } from 'react'
import publicIp from 'public-ip'
import { getOriginCountry } from '../services/ip-address'
import { api, Methods } from '../services/httpService'
import { getStorageItem } from '../util/session'

interface TaxRateInterface {
  tax: TaxRateResponse | undefined
}

const TaxRateContext = React.createContext<TaxRateInterface>(undefined)

interface Props {
  children: ReactNode | ReactNode[]
}

export interface TaxRateResponse {
  id: string
  description: string
  displayName: string
  country: string
  percentage: number
}

const ipKey = 'config:ip-address'
const countryKey = 'config:country'

const TaxRateProvider = ({ children }: Props) => {
  const [country, setCountry] = useState<string>(() => {
    const storedIp = getStorageItem(ipKey)
    if (storedIp) {
      const storedCountry = getStorageItem(countryKey)
      if (storedCountry) {
        return storedCountry
      }
      return undefined
    }
    return undefined
  })
  const [clientTaxRates, setClientTaxRates] = useState<TaxRateResponse[]>([])
  const [tax, setTax] = useState<TaxRateResponse | undefined>(undefined)

  useEffect(() => {
    const fetchTaxRates = async () => {
      const data = await api<TaxRateResponse[]>(
        Methods.get,
        '/config/tax-rates',
        {},
        { isBypassAuth: true },
      )
      setClientTaxRates(data)
    }

    fetchTaxRates()
  }, [])

  /**
   * Fetches Ip address and compares against stored Ip address
   * If different, will get new Ip address' metadata (country, etc..)
   * Since fetch Ip address's data is a paid third party API, using
   * local storage will help prevent unnecessary calls the API
   */
  useEffect(() => {
    const getIp = async () => {
      const ip = await publicIp.v4()
      const storedIp = getStorageItem(ipKey)

      if (ip !== storedIp) {
        localStorage.setItem(ipKey, ip)
        const ipCountry = await getOriginCountry(ip)
        setCountry(ipCountry)
        localStorage.setItem(countryKey, ipCountry)
      }
    }

    getIp()
  }, [country])

  useEffect(() => {
    if (country && !!clientTaxRates.length) {
      const taxRate = clientTaxRates.find(val => val.country === country)
      setTax(taxRate)
    }
  }, [country, clientTaxRates])

  const value = { tax }
  return (
    <TaxRateContext.Provider value={value}>{children}</TaxRateContext.Provider>
  )
}

export { TaxRateProvider, TaxRateContext }
