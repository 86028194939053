import { TrainerResponse } from './../redux/actions/fetchWorkouts'
import { TId } from './misc'
import { ITrainer } from './trainer'

export enum EWorkoutType {
  // HIIT = 'HIIT',
  // STRENGTH = 'Strength',
  // CARDIO = 'Cardio',
  // YOGA = 'Yoga',
  // DANCE = 'Dance',
  // BOXING = 'Boxing',
  // PILATES = 'Pilates',
  // CYCLING = 'Cycling',
  // BARRE = 'Barre',
  STARPOP = 'Starpop',
  RATCHET = 'Ratchet',
  CISE_CARIDO_SCULPT = 'Cise Cardio & Sculpt',
}

export enum EEquipment {
  NONE = 'None',
  BASIC = 'Basic',
  FULL_GYM = 'Full Gym',
}

export enum EWorkoutIntensity {
  BEGINNER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
}

export enum EWorkoutFormat {
  FULL_LENGTH = 'Full Length',
  ABBREVIATED = 'Abbreviated',
}

export const MIN_WORKOUT_LEN = 0
export const MAX_WORKOUT_LEN = 120

export enum EMuscleGroups {
  FULL_BODY = 'Full Body',
  LEGS = 'Legs',
  CORE = 'Core',
  CHEST = 'Chest',
  BACK = 'Back',
  SHOULDERS = 'Shoulders',
  BICEPS = 'Biceps',
  TRICEPS = 'Triceps',
}

export interface IWorkoutVideo {
  createdAt: string
  description: string
  id: TId
  sequence: number
  thumbnail: string
  title: string
  updatedAt: string
  videoURL: string
}

export interface IWorkout {
  thumbnail: string
  createdAt: string
  description: string
  equipment: EEquipment
  format: EWorkoutFormat
  id: TId
  igDate: string | null
  igLink: string | null
  intensity: EWorkoutIntensity
  length: number
  muscles: EMuscleGroups[]
  title: string
  type: EWorkoutType
  updatedAt: string
  video: IWorkoutVideo[]
  trainer: ITrainer
}

export enum EWorkoutVideoFormat {
  ZOOM = 'zoom',
  STREAM = 'stream',
}

export enum SignUpType {
  MEMBER = 'member',
  DROP_IN = 'dropIn',
}

export interface ILiveWorkout {
  thumbnail: string
  createdAt: string
  description: string
  equipment: EEquipment
  id: number
  igDate: string | null
  igLink: string | null
  intensity: EWorkoutIntensity
  workoutVideoFormat: 'zoom' | 'stream' | 'zoomLink'
  recordLiveWorkout: boolean
  usersAttending: {
    firstName: string
    id: number
    lastName: string
    profilePicture: string | null
    signUpType: SignUpType
  }[]
  length: number
  title: string
  type: EWorkoutType[] | null
  updatedAt: string
  startDate: string
  zoomLink: string
  priceId: string
  isForSale: boolean
  isIncludedInMembership: boolean
  workoutExercises: string | null
  trainer: ITrainer & { profilePictureUrl: string | null } & TrainerResponse
  images: {
    small: string
  }
}

export interface ClassPrice {
  priceId: string
  amount: number
  currency: string
  applicationFeeAmount: number
}

export interface LiveConfig {
  liveWorkoutTags: string[]
  liveWorkoutPrices: ClassPrice[]
  isLiveWorkoutsForSale: boolean
  videoQuality?: '1080p_1' | '720p_3' | '1440p_1'
}
