import * as session from '../session'
import { fetchTrainer, fetchDeleteTrainer } from './trainers'
import { IAthleteResponse, fetchAthlete, fetchDeleteAthlete } from './athletes'
import { TId } from '../../types'
import {
  defaultErrorMsg,
  getQueryParams,
} from '../../components/forms/util/helpers'
import { IWorkout } from '../../types/workout'
import { fetchWorkout } from './workouts'
import { getVoidPromise } from '../helpers'

const ID = 'id'

interface IFetchWithState {
  action: (...stuff: any) => Promise<any>
  params?: any[]
  setData: (data: any) => void
  setPending: (pending: boolean) => void
  setError: (error: string) => void
}

/**
 * Make a request to GET information from the server and update state via hooks
 *
 * @param props.action     - wrapper API call to make like `fetchAthlete`
 * @param props.params     - to pass to the action
 * @param props.setData    - hook to update with response to API if success
 * @param props.setPending - hook
 * @param props.setError   - hook
 */
export const fetchWithState = ({
  action,
  params = [],
  setData,
  setPending,
  setError,
}: IFetchWithState): Promise<void> =>
  action(...params)
    .then(res => {
      setData(res)
      setPending(false)
    })
    .catch(err => {
      setError((err && (err.msg || err.message)) || defaultErrorMsg)
      setPending(false)
    })

type IUserResponse = IAthleteResponse

/**
 * @param props.queryParam - name of the query parameter in the URL
 * @param props.setPending - hook for updating pending state
 * @param props.setError   - hook for updating error state
 * @param ...rest
 */
export const fetchWithStateWithQueryParam = ({
  queryParam,
  setPending,
  setError,
  ...rest
}: IFetchWithState & { queryParam: string }): Promise<void> => {
  const queryParams = getQueryParams()
  if (!queryParams) return getVoidPromise()
  const paramValue = queryParams.get(queryParam)

  if (!paramValue) {
    setPending(false)
    setError(`No "${queryParam}" provided.`)
    return getVoidPromise()
  }

  return fetchWithState({
    ...rest,
    setPending,
    params: [paramValue],
    setError,
  })
}

/**
 * Fetch the current user's data and update state
 *
 * @param userId of trainer or athlete currently logged in
 * @param setData hook
 * @param setPending hook
 * @param setError hook
 * @returns void Promise since data is updated via provided hook
 */
export const fetchUserData = async (
  userId: TId | undefined,
  setData: (res: IUserResponse) => void,
  setPending: (pending: boolean) => void,
  setError: (error: string) => void,
): Promise<void> => {
  if (!userId) return
  const isTrainer = session.isTrainer()
  const action = isTrainer ? fetchTrainer : fetchAthlete
  return fetchWithState({
    action,
    params: [userId],
    setData,
    setPending,
    setError,
  })
}

/**
 * Delete the current user's account (trainer or athlete)
 */
export const fetchDeleteUser = async (): Promise<void> => {
  const userId = session.getId()
  if (!userId) throw new Error('User Id must be defined')
  const isTrainer = session.isTrainer()
  const action = isTrainer ? fetchDeleteTrainer : fetchDeleteAthlete
  return await action(userId)
}

export const fetchWorkoutFromQueryParam = (
  setError: (err: string) => void,
  setPending: (pending: boolean) => void,
  setData: (data: IWorkout) => void,
): Promise<void> =>
  fetchWithStateWithQueryParam({
    queryParam: ID,
    action: fetchWorkout,
    setData,
    setPending,
    setError,
  })
