import HttpStatus from 'http-status-codes'
import { IAPIEndpoint } from './apiRoutes'
import { EResponseType, EContentType } from '../types/requests'
import { defaultErrorMsg } from '../components/forms/util/helpers'
import * as session from './session'
import { IMap } from '../types/misc'

export const API_ROOT =
  process.env.GATSBY_API_ROOT || 'https://solinfitness.com/api'
export const X_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME_HEADER || ''

const timeoutPromise = async (
  ms: number,
  promise: Promise<any>,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'))
    }, ms)
    promise.then(
      res => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      err => {
        clearTimeout(timeoutId)
        reject(err)
      },
    )
  })

const isOKStatus = (status: number): boolean => status >= 200 && status < 300

const throwErrorIfStatusIsError = (json?: IMap): void => {
  if (!json) return
  const { status } = json
  if (!status) return
  if (status.toLowerCase() !== 'error') return
  throw new Error(json.message || json.msg || json.error || defaultErrorMsg)
}

interface IRequestProps {
  responseType?: EResponseType
  contentType?: EContentType
  token?: string
  timeout?: number
}

const defaultProps: IRequestProps = {
  responseType: EResponseType.JSON,
  contentType: EContentType.JSON,
  timeout: 2 * 60 * 1000,
}

/**
 * Generic method for handling any request to the backend via API_ROOT
 *
 * @param route to request relative to ../api
 * @param data to send as the request body (if needed)
 */
const request = async (
  endpoint: IAPIEndpoint,
  data?: object | FormData,
  props: IRequestProps = {},
  byPassAuth?: boolean,
  customAuthHeader?: string,
  customApiRoot?: string,
): Promise<object | string | void> => {
  const propsWithDefault = { ...defaultProps, ...props }
  const { method, route } = endpoint
  const headers = new Headers()
  const {
    responseType,
    contentType,
    timeout,
    token: passedToken,
  } = propsWithDefault

  if (!byPassAuth && !passedToken) {
    await session.refreshAccessToken()

    const token = await session.getToken()

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
  }

  if (passedToken) {
    headers.set('Authorization', `Bearer ${passedToken}`)
  }

  if (customAuthHeader) {
    headers.set('Authorization', `${customAuthHeader}`)
  }

  // In the form data case we want fetch to set the headers itself
  // https://github.com/github/fetch/issues/505
  if (data && contentType !== EContentType.FORM_DATA) {
    headers.set('Content-Type', contentType as string)
  }

  headers.set('x-client-name', X_CLIENT_NAME)

  const options: RequestInit = { method, headers }

  if (data && Object.keys(data).length !== 0) {
    if (contentType === EContentType.JSON) {
      options.body = JSON.stringify(data)
    } else {
      options.body = data as FormData
    }
  }

  const response = await timeoutPromise(
    timeout as number,
    fetch(`${customApiRoot || API_ROOT}${route}`, options),
  )
  const status = response.status

  if (!isOKStatus(status)) {
    let json: IMap
    try {
      json = await response.json()
    } catch (err) {
      let errorMsg: string
      try {
        errorMsg = HttpStatus.getStatusText(status)
      } catch (httpStatusErr) {
        errorMsg = defaultErrorMsg
      }
      throw new Error(errorMsg)
    }
    throw new Error(json.msg || json.message || defaultErrorMsg)
  }

  if (responseType === EResponseType.TEXT) {
    const text = response.text()
    return text
  } else if (responseType === EResponseType.EMPTY) {
    return
  } else {
    // Default case is JSON response
    const json = await response.json()
    throwErrorIfStatusIsError(json)
    return json
  }
}

export default request
