import { ActionType, createAsyncAction } from 'typesafe-actions'

export interface WorkoutByIdResponse {
  id: number
  title: string
  description: string
  type: string
  length: number
  createdAt: string
  thumbnail: string
  muscles: string[]
  tags: string[]
  intensity: string
  equipment: string
  trainer: TrainerResponse
  video: VideoResponse[]
  headerOne?: string
  headerTwo?: string
  descriptionTwo?: string
  spotifyLink?: string
  published: boolean
}

interface TrainerResponse {
  id: number
  firstName: string
  lastName: string
  profilePictureURL: string
}

interface VideoResponse {
  id: number
  videoURL: string
}

export const onFetchWorkoutById = createAsyncAction(
  'workouts/FETCH_WORKOUT_BY_ID',
  'workouts/FETCH_WORKOUT_BY_ID_SUCCESS',
  'workouts/FETCH_WORKOUT_BY_ID_FAILURE',
)<number, WorkoutByIdResponse, Error>()

export type WorkoutByIdActions = ActionType<typeof onFetchWorkoutById>
