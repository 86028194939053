/**
 * List of all frontend routes
 */

// General
export const HOME_ROUTE = '/'
export const LIVE_CLASSES_PUBLIC = '/live-classes'
export const TERMS_ROUTE = '/terms'
export const PRIVACY_ROUTE = '/privacy'
export const SUPPORT_ROUTE = '/support'

// Account management
export const LOGIN_ROUTE = '/login'
export const PROFILE_ROUTE = '/profile'
export const FOROGT_PASSWORD_ROUTE = '/password/forgot'

// Workouts
export const WORKOUTS_ROUTE = '/workouts'
export const WORKOUTS_SEE_ALL_ROUTE = '/see-all'
export const MORE_ROUTE = '/more'
export const SUPPORT = '/support'
export const CONTACT = '/contact'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'

export const WORKOUT_VIDEO_ROUTE = (id: string | number) =>
  `/workout-video?id=${id}`

export const ATHLETE_LIVE_WORKOUT_DETAIL = (id: string | number) =>
  `/live-workout-detail?id=${id}`

export const LIVE_WORKOUT_DETAIL = (id: string | number) =>
  `/live/workout-detail-trainer?id=${id}`

export const LIVE_VIDEO_ROUTE = (id: string | number) => `/live/video?id=${id}`

export const TRAINER_DETAIL = (id: number) => `/trainer-profile?id=${id}`

export const FACEBOOK_LINK = process.env.GATSBY_CLIENT_FACEBOOK
export const INSTAGRAM_LINK = process.env.GATSBY_CLIENT_INSTAGRAM
export const CLIENT_NAME = process.env.GATSBY_CLIENT_NAME
export const HOME_CAPTION = process.env.GATSBY_HOME_CAPTION
export const CONTACT_EMAIL = process.env.GATSBY_CONTACT_EMAIL
export const SUPPORT_EMAIL = process.env.GATSBY_SUPPORT_EMAIL

// Live
export const LIVE_SCHEDULE_ROUTE = '/live/schedule'
export const LIVE_REGISTER = '/live/register'
export const LIVE_LOGIN = '/live/login'
export const LIVE_EDIT_PROFILE = '/live/edit-profile'
export const THEME_SETTINGS = '/live/theme-settings'
export const BASIC_LIVE_SCHEDULE = '/live-workouts'
export const TRAINER_EDIT_PROFILE = '/live/trainer/edit-profile'
export const TRAINER_ANALYTICS = '/live/trainer/analytics'

// trainer live
export const TRAINER_CREATE_WORKOUT = '/live/trainer/create-workout'
export const TRAINER_EDIT_WORKOUT = '/live/trainer/edit-workout'
export const TRAINER_SCHEDULE = '/live/schedule'
export const TRAINER_RECORDINGS = '/live/manage-workouts'
export const TRAINER_EDIT_VIDEO = '/live/edit-video'
export const TRAINER_UPLOAD_VIDEO = '/live/upload-video'
export const DASHBOARD = '/dashboard'
export const TRAINER_LOGIN = '/live/trainer/login'
export const TRAINER_REGISTER = '/live/trainer/register'
export const TRAINER_WORKOUT_VIDEO_ROUTE = (id: string | number) =>
  `/live/workout-video?id=${id}`

export const AUTH_FORGOT_PASSWORD_ROUTE = '/auth/forgot'
export const REGISTER_ROUTE = '/register'
