import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchLikedWorkouts,
  LikedWorkoutActions,
  WorkoutResponse,
} from '../actions'

const fetchLikedWorkouts: Epic = (
  action$: ActionsObservable<LikedWorkoutActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchLikedWorkouts.request)),
    mergeMap(() =>
      api$(Methods.get, '/favorite').pipe(
        map((workouts: any) =>
          onFetchLikedWorkouts.success(workouts as WorkoutResponse[]),
        ),
        catchError(err => of(onFetchLikedWorkouts.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchLikedWorkouts)
