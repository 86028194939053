import { ERequestMethod } from '../types/requests'
import { TId } from '../types'

const GET = ERequestMethod.GET
const POST = ERequestMethod.POST
const PATCH = ERequestMethod.PATCH
const DELETE = ERequestMethod.DELETE
const PUT = ERequestMethod.PUT

export interface IAPIEndpoint {
  route: string
  method: ERequestMethod
}

const r = (method: ERequestMethod, route: string): IAPIEndpoint => ({
  route,
  method,
})

// General routes
export const API_HEALTH_CHECK_ROUTE = r(POST, '/healthcheck')
export const API_UPLOAD_IMAGE_ROUTE = r(POST, '/upload/image')
export const API_FORGOT_PASSWORD_ROUTE = r(POST, '/reset/initiate')
export const API_RESET_PASSWORD_ROUTE = r(POST, '/reset')

// Auth routes
export const API_REGISTER_ROUTE = r(POST, '/auth/register')
export const API_TOKEN_ROUTE = r(POST, '/token')
export const API_VALIDATE_EMAIL_ROUTE = r(POST, '/validate-email')
export const API_EMAIL_ROUTE = r(POST, '/email')

// Workout routes
export const API_GET_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(GET, `/workout/${id}`)
export const API_CREATE_WORKOUT_ROUTE = r(POST, '/workout')
export const API_UPDATE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(PATCH, `/workout/${id}`)
export const API_DELETE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/workout/${id}`)
export const API_UPLOAD_WORKOUT_VIDEO_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/workout/${id}/video`)
export const API_UPDATE_WORKOUT_VIDEO_ROUTE = (
  workoutId: TId,
  videoId: TId,
): IAPIEndpoint => r(PATCH, `/workout/${workoutId}/video/${videoId}`)
export const API_DELETE_WORKOUT_VIDEO_ROUTE = (
  workoutId: TId,
  videoId: TId,
): IAPIEndpoint => r(DELETE, `/workout/${workoutId}/video/${videoId}`)

// Trainer routes
export const API_GET_TRAINERS_ROUTE = r(GET, '/trainer')
export const API_GET_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(GET, `/trainer/${id}`)
export const API_UPDATE_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(PATCH, `/trainer/${id}`)
export const API_DELETE_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/trainer/${id}`)
export const API_CHANGE_TRAINER_PASSWORD_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/trainer/${id}/change-password`)

// Athlete routes
export const API_GET_ATHLETES_ROUTE = r(GET, '/athlete')
export const API_GET_ATHLETE_ROUTE = (id: TId): IAPIEndpoint =>
  r(GET, `/athlete/${id}`)
export const API_UPDATE_ATHLETE_ROUTE = (id: TId): IAPIEndpoint =>
  r(PATCH, `/athlete/${id}`)
export const API_DELETE_ATHLETE_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/athlete/${id}`)
export const API_CHANGE_ATHLETE_PASSWORD_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/athlete/${id}/change-password`)

// Auth routes
export const API_LOGIN_ROUTE = r(POST, '/auth/login')

// Subscription & stripe routes
export const API_CREATE_ACC_BUY_WORKOUT = (id: TId): IAPIEndpoint =>
  r(POST, `/live-workout/${id}/create-account`)

export const API_PURCHASE_LIVE_WORKOUT = (id: number): IAPIEndpoint =>
  r(POST, `/live-workout/${id}/purchase`)

export const API_CREATE_SUBSCRIPTION = r(POST, '/subscription')
export const API_FETCH_SUBSCRIPTIONS = r(GET, '/subscription')
export const API_UPDATE_USER_PAYMENT = r(POST, '/subscription/update-payment')
export const API_FETCH_USER_PAYMENT = r(GET, '/subscription/payment')
export const API_CANCEL_SUBSCRIPTION = r(POST, '/subscription/cancel')
export const API_CHANGE_SUBSCRIPTION = r(POST, 'subscription/change')
export const API_ACTIVATE_SUBSCRIPTION = r(POST, '/subscription/activate')
export const API_UPGRADE_SUBSCRIPTION = r(POST, '/subscription/upgrade')

// Profile routes (athlete profile, liked workouts, scheduled workouts, completed workouts)
export const API_FETCH_USER_BY_ID = (id: TId): IAPIEndpoint =>
  r(GET, `/athlete-profile/user/${id}`)
export const API_FETCH_PROFILE = r(GET, '/athlete-profile')
export const API_FETCH_COMPLETED_WORKOUTS = r(GET, '/mark_completed')
export const API_FETCH_SCHEDULED_WORKOUTS = r(GET, '/scheduled-workouts')
export const API_FETCH_LIKED_WORKOUTS = r(GET, '/favorite')

// manage account routes
export const API_CHANGE_PASSWORD = r(POST, '/user/change-password')
export const API_EDIT_PROFILE = r(PUT, '/athlete-profile')
export const API_FETCH_USER_META = r(GET, '/user')

// subscribe email
export const API_ADD_TO_EMAIL_LIST = r(POST, '/email')

// password
export const API_FORGOT_PASSWORD = r(POST, '/reset/initiate')
export const API_RESET_PASSWORD = r(POST, '/reset')
