import { ERequestMethod } from '../types/requests'
import { TId } from '../types'

export const GET = ERequestMethod.GET
export const POST = ERequestMethod.POST
const PATCH = ERequestMethod.PATCH
const DELETE = ERequestMethod.DELETE
const PUT = ERequestMethod.PUT

export interface IAPIEndpoint {
  route: string
  method: ERequestMethod
}

export const r = (method: ERequestMethod, route: string): IAPIEndpoint => ({
  route,
  method,
})

// Agora recording routes
export const AGORA_ACQUIRE_RESOURCE_ID = (appId: string): IAPIEndpoint =>
  r(POST, `/v1/apps/${appId}/cloud_recording/acquire`)

export const AGORA_START_RECORDING = (
  appId: string,
  resourceid: string,
  mode: string,
): IAPIEndpoint =>
  r(
    POST,
    `/v1/apps/${appId}/cloud_recording/resourceid/${resourceid}/mode/${mode}/start`,
  )

export const AGORA_STOP_RECORDING = (
  appId: string,
  resourceid: string,
  mode: string,
  sid: string,
): IAPIEndpoint =>
  r(
    POST,
    `/v1/apps/${appId}/cloud_recording/resourceid/${resourceid}/sid/${sid}/mode/${mode}/stop`,
  )

// General routes
export const API_HEALTH_CHECK_ROUTE = r(POST, '/healthcheck')
export const API_UPLOAD_IMAGE_ROUTE = r(POST, '/upload/image')
export const API_FORGOT_PASSWORD_ROUTE = r(POST, '/reset/initiate')
export const API_RESET_PASSWORD_ROUTE = r(POST, '/reset')

// Auth routes
export const API_LOGIN_ROUTE = r(POST, '/auth/login')
export const API_REGISTER_ROUTE = r(POST, '/auth/register')
export const API_TOKEN_ROUTE = r(POST, '/token')
export const API_EMAIL_ROUTE = r(POST, '/email')

// Live Workout routes
export const API_GET_LIVE_WORKOUTS_ROUTE = (): IAPIEndpoint =>
  r(GET, '/live-workout/')

export const API_GET_ALL_LIVE_WORKOUTS_ROUTE = (): IAPIEndpoint =>
  r(GET, '/live-workout/all-workouts')

export const API_GET_LIVE_CONFIG = (): IAPIEndpoint =>
  r(GET, '/config/live-workout/')

export const API_GET_ADDITIONAL_HEADER_LINKS = (): IAPIEndpoint =>
  r(GET, '/config/additional-links/')

export const API_GET_LIVE_WORKOUT_DETAIL = (id: TId): IAPIEndpoint =>
  r(GET, `/live-workout/${id}`)

export const API_CREATE_LIVE_WORKOUT_ROUTE = (): IAPIEndpoint =>
  r(POST, '/live-workout/')

export const API_EDIT_LIVE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(PUT, `/live-workout/${id}`)

export const API_DELETE_LIVE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/live-workout/${id}`)

export const API_SIGN_UP_LIVE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/live-workout/${id}/signup`)

// Workout routes
export const API_CREATE_WORKOUT_FROM_REC_ROUTE = (): IAPIEndpoint =>
  r(POST, '/workout/create-from-recording')
export const API_GET_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(GET, `/workout/${id}`)
export const API_CREATE_WORKOUT_ROUTE = r(POST, '/workout')
export const API_UPDATE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(PATCH, `/workout/${id}`)
export const API_DELETE_WORKOUT_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/workout/${id}`)
export const API_UPLOAD_WORKOUT_VIDEO_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/workout/${id}/video`)
export const API_UPDATE_WORKOUT_VIDEO_ROUTE = (
  workoutId: TId,
  videoId: TId,
): IAPIEndpoint => r(PATCH, `/workout/${workoutId}/video/${videoId}`)
export const API_DELETE_WORKOUT_VIDEO_ROUTE = (
  workoutId: TId,
  videoId: TId,
): IAPIEndpoint => r(DELETE, `/workout/${workoutId}/video/${videoId}`)

// Trainer routes
export const API_GET_TRAINERS_ROUTE = r(GET, '/trainer')
export const API_GET_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(GET, `/trainer/${id}`)
export const API_UPDATE_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(PATCH, `/trainer/${id}`)
export const API_DELETE_TRAINER_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/trainer/${id}`)
export const API_CHANGE_TRAINER_PASSWORD_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/trainer/${id}/change-password`)

// Athlete routes
export const API_GET_ATHLETES_ROUTE = r(GET, '/athlete')

export const API_GET_USER_STATS = (): IAPIEndpoint => r(GET, '/user/stats')
export const API_GET_ATHLETE_ROUTE = (): IAPIEndpoint =>
  r(GET, '/athlete-profile')
export const API_UPDATE_ATHLETE_ROUTE = (): IAPIEndpoint =>
  r(PUT, '/athlete-profile')
export const API_DELETE_ATHLETE_ROUTE = (id: TId): IAPIEndpoint =>
  r(DELETE, `/athlete/${id}`)
export const API_CHANGE_ATHLETE_PASSWORD_ROUTE = (id: TId): IAPIEndpoint =>
  r(POST, `/athlete/${id}/change-password`)
