import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchSubscriptions,
  SubscriptionActions,
  SubscriptionResponse,
} from '../actions'

const fetchSubscriptions: Epic = (
  action$: ActionsObservable<SubscriptionActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchSubscriptions.request)),
    mergeMap(() =>
      api$(
        Methods.get,
        '/subscription',
        {},
        {
          isBypassAuth: true,
        },
      ).pipe(
        map((subscriptions: any) =>
          onFetchSubscriptions.success(subscriptions as SubscriptionResponse[]),
        ),
        catchError(err => of(onFetchSubscriptions.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchSubscriptions)
