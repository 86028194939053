import { ActionType, createAsyncAction } from 'typesafe-actions'

export enum UploadStatus {
  failed = 'failed',
  processing = 'processing',
  finished = 'finished',
}

export interface WorkoutResponse {
  id: number
  title: string
  description: string
  type: string
  length: number
  createdAt: string
  thumbnail: string
  muscles: string[]
  tags: string[]
  intensity: string
  equipment: string
  trainer: TrainerResponse
  headerOne?: string
  headerTwo?: string
  descriptionTwo?: string
  video: VideoResponse[]
  published?: boolean
  spotifyLink?: string
  uploadStatus: UploadStatus
  secondsWatched: number
  views: number
}

interface VideoResponse {
  id: number
  videoURL: string
}

export interface TrainerResponse {
  id: number
  firstName: string
  lastName: string
  profilePictureURL: string
}

export const onFetchWorkouts = createAsyncAction(
  'workouts/FETCH_WORKOUTS',
  'workouts/FETCH_WORKOUTS_SUCCESS',
  'workouts/FETCH_WORKOUTS_FAILURE',
)<void, WorkoutResponse[], Error>()

export type WorkoutActions = ActionType<typeof onFetchWorkouts>
