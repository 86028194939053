import { Action, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { createLogger } from 'redux-logger'
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable'
import rootEpic from '../redux/epics'
import createReducer from '../redux/reducers/rootReducers'
import { persistStore } from 'redux-persist'

export const configureStore = (cb: () => void) => {
  const epicMiddleware: EpicMiddleware<
    Action<any>,
    Action<any>,
    void,
    any
  > = createEpicMiddleware()
  const middleware: any[] = [epicMiddleware]

  // middleware.push(createLogger({}))

  const store = createStore(
    createReducer,
    undefined,
    composeWithDevTools(applyMiddleware(...middleware)),
  )

  const persistor = persistStore(store, null, () => {
    cb()
  })

  epicMiddleware.run(rootEpic)
  return { store, persistor }
}
