import { CompletedWorkoutResponse } from './../../actions/'
import { createReducer } from 'typesafe-actions'
import {
  CompletedWorkoutActions,
  onFetchCompletedWorkouts,
} from '../../actions'

interface CompletedWorkoutState {
  error: any
  isFetching: boolean
  completedWorkouts: CompletedWorkoutResponse[]
}

export const defaultState: CompletedWorkoutState = {
  error: null,
  isFetching: false,
  completedWorkouts: [],
}

const reducer = createReducer<CompletedWorkoutState, CompletedWorkoutActions>(
  defaultState,
)
  .handleAction(onFetchCompletedWorkouts.request, state => ({
    ...state,
    error: null,
    isFetching: true,
  }))
  .handleAction(onFetchCompletedWorkouts.success, (_, action) => ({
    error: null,
    isFetching: false,
    completedWorkouts: action.payload,
  }))
  .handleAction(onFetchCompletedWorkouts.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isFetching: false,
  }))

export default reducer
