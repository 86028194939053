/* eslint-disable camelcase */
import { api, Methods } from './httpService'

const baseUrl = 'https://ipapi.co/'

interface IpResponse {
  asn: string
  city: string
  continent_code: string
  country: string
  country_area: number
  country_calling_code: string
  country_capital: string
  country_code: string
  country_code_iso3: string
  country_name: string
  country_population: number
  country_tld: string
  currency: string
  currency_name: string
  in_eu: boolean
  ip: string
  languages: string
  latitude: string
  longitude: string
  message: string
  org: string
  postal: string
  region: string
  region_code: string
  timezone: string
  utc_offset: string
  version: string
}

export const getOriginCountry = async (ip: string): Promise<string> => {
  const res = await api<IpResponse>(
    Methods.get,
    `${ip}/json`,
    {},
    { isBypassAuth: true, baseUrl },
  )
  return res.country
}
