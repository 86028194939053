import { Epic, ActionsObservable, combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { api$, Methods } from '../../services/httpService'
import { isActionOf } from 'typesafe-actions'
import {
  onFetchScheduledWorkouts,
  ScheduledWorkoutsActions,
  ScheduledWorkoutResponse,
} from '../actions'

const fetchScheduledWorkouts: Epic = (
  action$: ActionsObservable<ScheduledWorkoutsActions>,
) =>
  action$.pipe(
    filter(isActionOf(onFetchScheduledWorkouts.request)),
    mergeMap(() =>
      api$(Methods.get, '/scheduled-workouts').pipe(
        map((scheduledWorkouts: any) =>
          onFetchScheduledWorkouts.success(
            scheduledWorkouts as ScheduledWorkoutResponse[],
          ),
        ),
        catchError(err => of(onFetchScheduledWorkouts.failure(err))),
      ),
    ),
  )

export default combineEpics(fetchScheduledWorkouts)
