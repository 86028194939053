const { NODE_ENV } = process.env

/**
 * Only output logs to the console if the app is running in development mode
 *
 * @param data any arbitrary list of parameters to print
 */
export const log = (...data: any[]): void => {
  if (NODE_ENV === 'development') {
    console.log(...data) // eslint-disable-line
  }
}
